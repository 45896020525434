<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Animals</h1>
        <p>
          This is an ecosystem restoration effort and also an operation to produce food for humans. Over centuries
          humans and some animal species have entered into a symbiotic relationship and out of that comes that some
          species are considered farm animals while other species are wild animals.
        </p>

        <h2>Farm Animals</h2>
        <p>
          Our farm animals are currently cattle (Bos taurus), pigs (Sus scrofa domesticus), sheep (Ovis aries), dogs
          (Canis familiaris) and horses (Equus ferus caballus). You can learn more about each species and why there are
          here by following the links to the left.
        </p>
        <p v-if="hasListAnimals" class="py-2">
          Some animal species are part of our operation to produce food for humans. Please check out the respective
          section to learn more.
        </p>
        <p>
          There are also a few cats around who hang out around the buildings or garden like areas where the dogs cannot
          go.
        </p>

        <h2>Wild Animals</h2>
        <p>
          As we are in a part of the spanish Dehesa in an area with little human activity we do get visited frequently
          by wildlife. There is a larger number of birds that seems to be increasing due to our activity. Birds love to
          go through cow patties as they find fly larvae and other insects in there.
        </p>
        <p>
          Close by is a protected area where <a target="_blank" href="https://en.wikipedia.org/wiki/Iberian_lynx">Iberian
            Lynx</a> has
          been set free. We have not yet seen one here yet. Other animal species we have seen up close so far include:
        </p>
        <ul>
          <li>Wild boar (Sus scrofa)</li>
          <li>Deer (Cervidae)</li>
          <li>Fox</li>
          <li>Rabbit (Oryctolagus cuniculus)</li>
        </ul>
        <p>
          There are, of course, also the usual other smaller critters like mice and rats. There are frogs, lizards and
          probably more than we can remember at the time of writing.
        </p>
        <p>
          Some of these animal species are predators and they do attack our livestock if not properly protected. We
          accept that fact as it is part of how nature works and try to protect our farm animals as best as possible.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import { setupFeatureFlags } from '@/library'

export default {
  name: "AnimalsEN",
  components: {
    Sidebar,
  },

  setup () {
    return setupFeatureFlags()
  },

};
</script>
